<template>
  <b-modal id="account-profile-image" :title="$t('profile_image')" modal-class="account-modal" hide-footer>
    <b-alert :show="!!photoError" variant="danger">
      <i class="fa-solid fa-triangle-exclamation"></i>
      {{ photoError }}
    </b-alert>
    <b-form @submit="submitForm">
      <vue-dropzone
        :awss3="awsS3"
        :options="dropzoneOptions"
        :include-styling="true"
        :useCustomSlot=true
        id="profile-dropzone"
        class="bg-light border rounded-lg p-5 text-center"
        ref="profileDropzone"
        v-on:vdropzone-s3-upload-error="s3UploadError"
        v-on:vdropzone-s3-upload-success="s3UploadSuccess"
        v-on:vdropzone-thumbnail="checkDimension"
        v-on:vdropzone-sending="sending"
        v-on:vdropzone-error="displayError"
      >
        <div class="dropzone-custom-content text-muted">
          <i class="fa-solid fa-cloud-arrow-up fa-3x mb-2"></i>
          <br/>
          {{ $t("dropzone_placeholder").toUpperCase() }}
        </div>
      </vue-dropzone>
      <hr />
      <b-row class="px-3 flex-column-reverse flex-md-row">
        <b-button variant="outline-primary" class="col-12 col-md-3 offset-md-3 mt-3" @click="$bvModal.hide('account-profile-image')">{{ $t('cancel') }}</b-button>
        <b-button v-if="!savingForm" variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">{{ $t('save') }}</b-button>
        <b-button v-else disabled variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">
          <b-spinner class="" small label="Large Spinner" />
        </b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'modal-account-image',
  props: {
    image: {
      type: String,
      required: true
    }
  },
  components: {
    vueDropzone
  },
  data () {
    return {
      photo: this.image,
      photoError: null,
      savingForm: false,
      awsS3: {
        signingURL: ApiService.getTokenUrl('profile'),
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: {},
        sendFileToServer: false
      },
      dropzoneOptions: {
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 15,
        maxFiles: 1,
        acceptedFiles: 'image/bmp,image/gif,image/jp2,image/jpeg,image/png,image/svg+xml,image/tif,image/tiff,image/vnd.ms-photo,image/vnd.adobe.photoshop,image/webp,image/x-icon',
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        renameFile (file) {
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        },
        accept (file, done) {
          file.acceptDimensions = done
          file.rejectDimensions = () => { done('Invalid dimension.') }
        }
      }
    }
  },
  methods: {
    submitForm (e) {
      e.preventDefault()
      this.photoError = null
      if (this.image !== this.photo) {
        this.savingForm = true
        ApiService.updateUserPhoto(this.photo).then(() => {
          this.$emit('calculateProgress', { image: this.photo }).then(() => {
            this.savingForm = false
            this.$bvModal.hide('account-profile-image')
          })
        }).catch((e) => {
          this.photoError = e.response.data.errors.children.photo.errors[0]
          this.savingForm = false
        })
      } else {
        this.$bvModal.hide('account-profile-image')
      }
    },
    s3UploadError () {
      this.photoError = this.$t('invalid_file')
    },
    s3UploadSuccess (s3Url) {
      this.photo = s3Url
    },
    checkDimension (file) {
      if (file.width < 130 || file.height < 130) {
        file.rejectDimensions()
        this.photoError = (file.width < 130) ? this.$t('dimension.width', { 'minWidth': 130, 'imgWidth': file.width }) : this.$t('dimension.height', { 'minHeight': 130, 'imgHeight': file.height })
      } else {
        file.acceptDimensions()
      }
    },
    sending (file) {
      if (!file.accepted) {
        file.xhr.timeout = 1
      }
    },
    displayError (file, message) {
      this.errorMessage = message
    }
  }
}
</script>

<style scoped>
.dropzone {
  border-style: dashed !important;
  border-width: 2px !important;
}
.dropzone-custom-content {
  font-weight: 500;
}
</style>
