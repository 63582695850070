<template>
  <b-modal id="account-profile-languages" :title="$t('languages')" modal-class="account-modal" hide-footer>
    <b-alert :show="!!languagesError" variant="danger">
      <i class="fa-solid fa-triangle-exclamation"></i>
      {{ languagesError }}
    </b-alert>
    <b-form @submit="submitForm">
      <b-row>
        <div class="col-12 col-sm-8">
          <b-form-select
            id="language-add"
            v-model="selected"
            :options="options"
          />
        </div>
        <div class="col-12 col-sm-4 mt-3 mt-sm-0">
          <b-button variant="outline-primary w-100" @click="add()">{{ $t('add_language') }}</b-button>
        </div>
      </b-row>
      <hr/>
      <div v-if="languagesSelected.length > 0" class="text-center">
        <div class="">{{ $t('selected_languages') }}</div>
        <div :id="'lang-' + lang.code" class="my-1" v-for="(lang) in languagesSelected" :key="lang.code">
          {{ lang.name }}
          <i class="fa-solid fa-circle-minus text-secondary cursor-pointer" aria-hidden="true" @click="remove(lang.code)"></i>
        </div>
      </div>
      <b-row class="px-3 flex-column-reverse flex-md-row">
        <b-button variant="outline-primary" class="col-12 col-md-3 offset-md-3 mt-3" @click="cancel()">{{ $t('cancel') }}</b-button>
        <b-button v-if="!savingForm" variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">{{ $t('save') }}</b-button>
        <b-button v-else disabled variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">
          <b-spinner class="" small label="Large Spinner"/>
        </b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'modal-account-languages',
  props: {
    languages: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selected: null,
      options: [],
      languagesSelected: [...this.languages],
      languagesError: null,
      savingForm: false
    }
  },
  created () {
    this.options = [{ value: null, text: this.$t('choose_language'), disabled: true }]
    ApiService.getAllLanguages().then((r) => {
      const that = this
      r.data['data'].forEach(function (o) {
        that.options.push({ value: o['code'], text: o['name'] + ' (' + o['name_local'] + ')' })
      })
    }).catch(() => {
    })
    this.languagesSelected.forEach(function (lang, index, array) {
      lang.name += ' (' + lang.name_local + ')'
      array[index] = lang
    })
  },
  methods: {
    submitForm (e) {
      e.preventDefault()
      this.languagesError = null
      if (this.languagesSelected.length > 0) {
        this.savingForm = true
        let payload = []
        this.languagesSelected.forEach(lang => {
          payload.push(lang.code)
        })
        ApiService.patchUser({ languages: payload }).then(() => {
          this.$emit('calculateProgress', { languages: this.languagesSelected })
          this.savingForm = false
          this.$bvModal.hide('account-profile-languages')
        }).catch((e) => {
          this.languagesError = e.response.data.errors.children.languages.errors[0]
          this.savingForm = false
        })
      } else {
        this.languagesError = this.$t('language_must_selected')
      }
    },
    add () {
      const select = document.getElementById('language-add')
      if (select.value && this.languagesSelected.filter(function (e) {
        return e.code === select.value
      }).length === 0) {
        this.languagesSelected.push({
          code: select.value,
          name: select.options[select.selectedIndex].text
        })
      }
    },
    remove (lang) {
      document.getElementById('lang-' + lang).remove()
      this.languagesSelected = this.languagesSelected.filter(function (obj) {
        return obj.code !== lang
      })
    },
    cancel () {
      this.languagesSelected = [...this.languages]
      this.$bvModal.hide('account-profile-languages')
    }
  }
}
</script>
