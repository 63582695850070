<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <div class="tasks-container w-100" >
        <div v-if="preloader" class="h-100 w-100 d-flex justify-content-center align-items-center">
            <b-spinner class="" label="Small Spinner" />
        </div>
        <b-tabs v-else nav-wrapper-class="d-none" >
          <TasksTab
            title="tasksAll"
            :tasks="getData('tasksAll')"
            :tasksWidget="true"
            :user="user"
          />
        </b-tabs>
      </div>
    </template>
  </WidgetTemplate>
</template>

<script>
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'
import ApiService from '@/apiService'
import TasksTab from '../MyAccount/Tabs/TasksTab.vue'
export default {
  name: 'TasksWidget',
  widgetType: 'Seller',
  components: { WidgetTemplate, TasksTab },
  data () {
    return {
      name: this.$options.name,
      user: null,
      preloader: true,
      tasks: [],
      tabs: [
        'tasksAll'
      ]
    }
  },
  created () {
    this.getTasks()
  },
  methods: {
    getTasks () {
      ApiService.getTasksByUser(localStorage.getItem('username')).then(response => {
        this.user = response.data.data.user
        this.tasks = response.data.data.gigs
        this.preloader = false
      }).catch(() => {})
    },
    getData (string) {
      return this[string]
    }
  },
  computed: {
    tasksAll: function () {
      return this.tasks.filter(function (task) {
        return task.status !== -1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tasks-container {
  height: 360px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::v-deep .card {
  .body {
    -ms-flex-direction: row !important;
    -webkit-flex-direction: horizontal !important;
    -webkit-flex-orient: normal !important;
    flex-direction: row !important;

    .assets {
      width: calc(50%) !important;
      display: flex !important;
    }

    .content {
      width: calc(50%) !important;
    }
  }
}

</style>
