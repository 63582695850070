<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <b-alert v-model="showErrorAlert" variant="danger" dismissible>
        <ul class="mb-0" v-if="Array.isArray(errorMessage)">
          <li v-for="(errors, index) in errorMessage" v-bind:key="index"> {{ errors[0] }}</li>
        </ul>
        <p v-else class="mb-0">
          {{ errorMessage }}
        </p>
      </b-alert>

      <b-alert class="alert position-absolute" v-model="showSuccessAlert" variant="success" dismissible>
        {{ $t('success_settings') }}
      </b-alert>

      <div class="row pt-4 pl-3 pr-3 mb-3">
        <div class="col-12">
          <div class="mt-0 mb-0 text-left">{{ $t('away.settings_para') }}</div>
        </div>
        <div class="col-12 mt-4">
          <b-form-radio-group
            class="d-flex"
            v-model="away.data.active">
            <b-form-radio class="btn btn-outline-dark rounded w-100 mb-2 radio-container" value="away">{{ $t('away.option1') }}</b-form-radio>
            <b-form-radio class="btn btn-outline-dark rounded w-100 mb-2 mr-0 radio-container" value="active" >{{ $t('away.option2') }}</b-form-radio>
          </b-form-radio-group>
          <b-input-group class="w-100">
            <b-form-input id="date-input"
                          v-model="away.data.date"
                          type="text"
                          :placeholder="$t('away.placeholder')"
                          autocomplete="off"/>
            <b-input-group-append class="">
              <b-form-datepicker v-model="away.data.date" button-only right :hide-header="true" :value-as-date="false" :min="setDatePickerValidation()"/>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-button v-if="!away.loader" class="submitBtn btn btn-primary mb-3 col-11 col-md-6" :disabled="away.original.date === away.data.date && away.original.active === away.data.active" v-on:click="submitData(false)">{{ $t('away.settings_button') }}</b-button>
      <b-button v-else class="submitBtn btn btn-primary mb-3 col-11 col-md-6">
        <b-spinner small label="Large Spinner"/>
      </b-button>
    </template>
  </WidgetTemplate>
</template>

<script>
import ApiService from '../../apiService'
import { mapActions } from 'vuex'
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'

export default {
  name: 'HolidayWidget',
  widgetType: 'Seller',
  components: { WidgetTemplate },
  data () {
    return {
      name: this.$options.name,
      fetchedUser: false,
      away: {
        data: {
          date: '',
          active: false
        },
        original: {
          date: '',
          active: false
        },
        loader: false
      },
      errorMessage: '',
      showErrorAlert: false,
      showSuccessAlert: false,
      lastLoggedIn: ''
    }
  },
  mounted () {
    this.getUser()
  },
  methods: {
    ...mapActions([
      'updateRefreshToken'
    ]),
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    },
    setDatePickerValidation () {
      let date = new Date()
      date.setDate(date.getDate() + 1)
      return date
    },
    getUser () {
      ApiService.getUser().then((response) => {
        this.away.original.active = response.data.data.away ? 'away' : 'active'
        this.away.original.date = response.data.data.returning_date === null ? '' : response.data.data.returning_date
        this.away.data.active = this.away.original.active
        this.checkDateAway()
        this.fetchedUser = true
      }).catch(() => {
      })
    },
    checkDateAway () {
      let date = this.away.original.date
      if (date !== null && date !== '') {
        let returningDate = new Date(date)
        let currentDate = new Date()

        if (returningDate > currentDate) {
          let day = returningDate.getDate()
          let month = returningDate.getMonth() + 1
          let displayDate = returningDate.getFullYear() + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day)
          this.away.original.date = displayDate
          this.away.data.date = displayDate
        } else {
          this.away.original.date = ''
          this.away.data.date = ''
          this.away.data.active = 'active'
        }
      }
    },
    createData () {
      this.showErrorAlert = false
      this.showSuccessAlert = false
      let payload = {}

      this.away.loader = true
      if (this.away.data.active === 'away') {
        payload.away = 1
        if (this.away.data.date !== '') {
          let date = new Date(this.away.data.date)
          payload.returning_date = {
            'date': {
              'year': date.getFullYear(),
              'month': date.getMonth() + 1,
              'day': date.getDate()
            },
            'time': {
              'hour': '0',
              'minute': '0'
            }
          }
        }
      } else {
        payload.away = 0
        this.away.data.date = ''
      }

      return payload
    },
    submitData () {
      let payload = this.createData()
      ApiService.patchUser(payload).then(() => {
        this.showSuccessAlert = true
        this.away.loader = false
        this.away.original.active = this.away.data.active
        this.away.original.date = this.away.data.date
      }).catch((error) => {
        this.onErrorUser(error)
      })
    },
    onErrorUser (error) {
      if (error.response.data.errors !== undefined) {
        const errors = []
        if (error.response.data.errors.errors !== undefined) {
          errors.push(error.response.data.errors.errors)
        }
        if (error.response.data.errors.children.returning_date.errors !== undefined) {
          errors.push(error.response.data.errors.children.returning_date.errors)
        }
        this.errorMessage = errors
      } else {
        this.errorMessage = error.response.data.error_message
      }
      this.away.loader = false
      this.showErrorAlert = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .radio-container {
  padding-left: 2rem;

  label {
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
}

.alert {
  z-index: 100;
}
</style>
