<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
    <div v-if="tasksFetched" class="w-100">
      <div v-if="tasks.length > 0" class="text-left pt-3 flex-row">
        <div v-for="(task, index) in tasks" v-bind:key="task.hash" class="col-12 card-item py-2 my-1">
          <Card
            :isFeaturedTask="false"
            :deliveryTime="task.packages[0].delivery"
            :index="index"
            :carousel-image="task"
            :image-icon="task.user.photo_path"
            :username="task.user.username"
            :description="task.title"
            :rating="task.review_average"
            :count="task.review_count"
            :user_rating="task.user.review_average"
            :user_count="task.user.review_count"
            :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
            :price="task.packages[0].price"
            :select="true"
            :hash="task.hash"
            :slug="task.slug"
            :saveTasksPage="true"
            :sellerInfo="task.user"
            @removedSavedTask="removeTask"
            :savedRecentWidget="true"
          />
        </div>
      </div>
      <div v-else class="mx-auto pt-3 d-flex flex-col align-items-center no-saved-tasks">
        <h4>{{ $t('saved_tasks_empty_heading') }}</h4>
        <p> {{ $t('saved_tasks_empty_message') }}</p>
        <i class="mb-3 fa fa-heart" aria-hidden="true"></i>
      </div>
    </div>
    <div v-else class="mx-auto d-flex align-items-center flex-col my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div class="mt-2">{{ $t('loading_tasks') }}</div>
    </div>
    </template>
  </WidgetTemplate>
</template>

<script>
import ApiService from '../../apiService'
import helper from '../../helper'
import Card from '../Task/Card'
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'

export default {
  name: 'SavedWidget',
  widgetType: 'Buyer',
  components: { Card, WidgetTemplate },
  data () {
    return {
      name: this.$options.name,
      tasks: [],
      tasksFetched: false
    }
  },
  mounted () {
    this.getFavouriteTasks()
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  methods: {
    formatMobileUrl (filename, filetype) {
      return helper.formatMobileUrl(filename, filetype)
    },
    getFavouriteTasks () {
      this.tasksFetched = false
      ApiService.favouriteTasks().then(response => {
        this.tasks = response.data.data[0]
      }).catch((error) => {
        ApiService.showError(error)
      }).finally(() => {
        this.tasksFetched = true
      })
    },
    removeTask (index) {
      if (this.tasks.length > index) {
        this.tasks.splice(index, 1)
      }
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-row {max-height: 375px; overflow: scroll;}
  ::-webkit-scrollbar {width: 6px; height: 6px;}
  ::-webkit-scrollbar-track {border-radius: 10px;}
  ::-webkit-scrollbar-thumb {border-radius: 10px; background-color: #bbb;}

  ::v-deep .card {
    overflow: visible !important;
    .body {
      -ms-flex-direction: row !important;
      -webkit-flex-direction: horizontal !important;
      -webkit-flex-orient: normal !important;
      flex-direction: row !important;
      .assets {
        width: calc(50%) !important;
        display: flex !important;
      }
      .content {
        width: calc(50%) !important;
      }
    }

    .content {
      z-index: 1 !important;
    }

    .actions {
      right: -6px !important;
      z-index: 1 !important;
    }
    .user-online.badge {
      position: relative !important;
    }
    .username {
      color: $light-grey !important;
    }
    .copy, .task-description {
      min-height: 0px !important;
    }
    .order-1 {order: 1 !important;}
    .order-2 {order: 2 !important;}
    .order-3 {order: 3 !important;}
  }
</style>
