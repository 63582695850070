<template>
  <b-card header-tag="header" body-class="p-0" id="" class="text-left widget" header-class="bg-navy text-white">
    <template #header>
      <slot name="name">{{ $t('widgets.title.' + widgetName) }}</slot>
      <div class='widget-close float-right text-secondary' @click="$emit('removeWidget')"><i class='fa fa-close'></i></div>
      <div class='d-sm-none widget-toggle-minimise float-right mr-2 text-primary' @click="toggleMinimised"><i :class="[ minimised ? 'fa fa-plus' : 'fa fa-minus']"></i></div>
      <div class='widget-move float-right mr-2 text-primary'><i class='fa fa-arrows'></i></div>
    </template>
    <main v-if="!minimised" class="d-sm-none d-flex flex-col align-items-center">
      <slot name="main" class="d-none"></slot>
    </main>
    <main class="d-none d-sm-flex flex-col align-items-center">
      <slot name="main"></slot>
    </main>
  </b-card>
</template>

<script>
export default {
  name: 'widgetTemplate.vue',
  props: {
    widgetName: String
  },
  data () {
    return {
      minimised: localStorage.hasOwnProperty(this.widgetName)
    }
  },
  methods: {
    toggleMinimised () {
      if (this.minimised) {
        localStorage.removeItem(this.widgetName)
        this.minimised = false
      } else {
        localStorage.setItem(this.widgetName, 'minimised')
        this.minimised = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  max-height: 421px;
  min-width: 100%;
  max-width: 407px;

  .widget-close, .widget-toggle-minimise {
    cursor: pointer; pointer-events: auto;
  }

  .widget-move {
    cursor: move;
  }
}
</style>
