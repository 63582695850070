<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <div class="mx-auto my-5 text-center" v-if="!loaded">
        <b-spinner large label="Large Spinner"/>
      </div>
      <div v-else-if="jobs.length > 0">
        <table class="table table-striped table-bordered add-manage-table border-bottom">
          <tbody>
          <tr v-for="(job, index) in jobs" :key="index">
            <td>{{ job.user.username }}</td>
            <td>{{ job.title }}</td>
            <td>
              <router-link :to="{ name: 'jobs_view', params: { 'hash': job.hash } }" class="btn btn-outline-primary">{{ $t('view') }}</router-link>
            </td>
          </tr>
          </tbody>
        </table>
        <router-link :to="{ name: 'job_board' }" tag="button" class="btn btn-primary mx-auto mb-3 d-flex">
          {{ $t('widgets.JobBoardWidget.button') }}
        </router-link>
      </div>
      <div v-else class="text-center m-3">
        <p>{{ $t('widgets.JobBoardWidget.no_results') }}</p>
      </div>
    </template>
  </WidgetTemplate>
</template>

<script>
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'
import ApiService from '../../apiService'

export default {
  name: 'JobBoardWidget',
  widgetType: 'Seller',
  components: { WidgetTemplate },
  data () {
    return {
      name: this.$options.name,
      loaded: false,
      jobs: []
    }
  },
  mounted () {
    ApiService.getJobListing(1, 4).then((resp) => {
      this.jobs = resp.data.data.results
      this.loaded = true
    })
  }
}
</script>
