<template>
  <b-modal id="account-profile-summary" :title="$t('profile_description')" modal-class="account-modal" hide-footer>
    <p class="text-center mt-0">{{ $t('profile_description_help') }}<br/>{{ $t('let_buyers_know_about_you') }}</p>
    <b-alert :show="!!summaryError" variant="danger">
      <i class="fa-solid fa-triangle-exclamation"></i>
      {{ summaryError }}
    </b-alert>
    <b-form @submit="submitForm">
      <vue-editor id="textarea-state" v-model="description" :state="descriptionState" :editorToolbar="toolbar" class="text-left"/>
      <hr/>
      <b-row class="px-3 flex-column-reverse flex-md-row">
        <b-button variant="outline-primary" class="col-12 col-md-3 offset-md-3 mt-3" @click="$bvModal.hide('account-profile-summary')">{{ $t('cancel') }}</b-button>
        <b-button v-if="!savingForm" variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">{{ $t('save') }}</b-button>
        <b-button v-else disabled variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">
          <b-spinner class="" small label="Large Spinner"/>
        </b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'modal-account-summary',
  props: {
    summary: {
      type: String,
      required: true
    }
  },
  components: {
    VueEditor
  },
  data () {
    return {
      description: this.summary,
      descriptionState: this.summary.length > 0,
      summaryError: null,
      savingForm: false,
      toolbar: [['bold', 'italic', 'underline']]
    }
  },
  methods: {
    submitForm (e) {
      e.preventDefault()
      this.summaryError = null
      if (this.description.length === 0 || this.description.replace(/(<([^>]+)>)/gi, '').match(/^\s*$/) !== null || this.description.length >= 255) {
        this.summaryError = this.$t('summary_length')
      } else if (this.description !== this.summary) {
        this.savingForm = true
        ApiService.patchUser({ summary: this.description }).then(() => {
          this.$emit('calculateProgress', { summary: this.description })
          this.savingForm = false
          this.$bvModal.hide('account-profile-summary')
        }).catch((e) => {
          this.summaryError = e.response.data.errors.children.summary.errors[0]
          this.savingForm = false
        })
      } else {
        this.$bvModal.hide('account-profile-summary')
      }
    }
  }
}
</script>
