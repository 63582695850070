<template>
  <div class="bg-white pb-5 pt-4">
    <b-container fluid>
      <div class="row d-flex rounded dashboard-header py-4 mb-4 text-white position-relative  justify-content-center">
        <div class="background rounded w-100 h-100 position-absolute d-none d-md-block"></div>
        <div class="d-block d-md-none order-0 mb-2 col-12 text-md-left text-center ml-md-5">
          <span class="dashboard-header-welcome">{{ $t('dashboard.banner.welcome') }}<br/>{{ username }}</span><br/>
          <span class="dashboard-header-date my-2">{{ currentDate() }}</span>
        </div>
        <div class="order-2 col-md-4 w-100 text-md-left d-sm-block pl-sm-0 d-flex flex-col align-items-center mx-md-0 order-md-0">
          <div class="d-md-block d-none">
            <span class="dashboard-header-welcome">{{ $t('dashboard.banner.welcome') }} <br/>{{ username }}</span><br/>
            <span class="d-block my-2">{{ currentDate() }}</span>
          </div>
          <b-dropdown class="mt-2 position-relative col-md-5 pl-md-0 col-12" no-flip id="dropdown-add-widget" text="Add Widget" variant="light">
            <div v-for="(widget, index) in widgets.list" :key="index">
              <b-dropdown-header
                v-if="index === 0 || widgets.list[index - 1] && widget[1] !== widgets.list[index - 1][1]"
                class="header border-bottom">
                {{ $t('widgets.type.' + widget[1]) }}
              </b-dropdown-header>
              <b-dropdown-item :id="'item' + index" class="dropdown-item" @click="addWidget(index)">
                {{ $t('widgets.title.' + widget[0]) }}
                <i v-if="displayedWidgets.includes(widgets.list.findIndex(item => item[0] === widget[0]))" class="fa-solid fa-check ml-auto pl-3 pr-1 pt-1"></i>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>
        <div
          class="col-md-2 col-10 my-1 mx-md-2 py-md-3 d-flex flex-md-column justify-content-center h-100 rounded cursor-pointer align-middle align-items-center dashboard-cta-1"
          @click="$router.push('sales')"
          v-if="$store.getters.hasRole('ROLE_SELLER')">
          <div class="dashboard-cta-icon d-md-block d-inline float-left float-md-none">
            <i class="fa fa-usd mr-3 mr-md-0" aria-hidden="true"></i>
          </div>
          <span class="dashboard-cta-header mt-2 mt-md-0 mb-md-2">{{ $t('dashboard.banner.view_sales') }}</span>
        </div>
        <div
          class="col-md-2 py-md-3 col-10 d-flex flex-md-column justify-content-center align-items-center h-100 rounded cursor-pointer align-middle dashboard-cta-1 my-1 mx-md-2"
          @click="$router.push('purchases')" v-else>
          <div class="dashboard-cta-icon d-md-block d-inline float-left float-md-none">
            <i class="fa fa-usd mr-3 mr-md-0" aria-hidden="true"></i>
          </div>
          <span class="dashboard-cta-header mt-2 mt-md-0 mb-md-2">{{ $t('dashboard.banner.view_purchases') }}</span>
        </div>
        <div
          class="col-md-2 py-md-3 col-10 d-flex flex-md-column justify-content-center align-items-center h-100 rounded cursor-pointer align-middle dashboard-cta-2 my-1 mx-md-2"
          @click="$router.push('inbox')">
          <div class="dashboard-cta-icon d-md-block d-inline float-left float-md-none">
            <i class="fa fa-inbox mr-3 mr-md-0" aria-hidden="true"></i>
          </div>
          <span class="dashboard-cta-header mt-2 mt-md-0 mb-md-2">{{ $t('dashboard.banner.view_inbox') }} <span
            v-if="getNewMessageCount() > 0">({{ getNewMessageCount() }})</span></span>
        </div>
        <div
          class="col-md-2 py-md-3 col-10 d-flex flex-md-column justify-content-center align-items-center h-100 rounded cursor-pointer align-middle dashboard-cta-3 my-1 mx-md-2"
          @click="$router.push({ name: 'task_or_job', params: { referrer: 'dashboard' } })"
          v-if="$store.getters.hasRole('ROLE_SELLER') || $store.getters.hasRole('ROLE_PRE_SELLER')">
          <div class="dashboard-cta-icon d-md-block d-inline float-left float-md-none">
            <i class="fa fa-file mr-3 mr-md-0" aria-hidden="true"></i>
          </div>
          <span class="dashboard-cta-header mt-2 mt-md-0 mb-md-2">{{ $t('dashboard.banner.create_task') }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center  justify-content-center">
        <div v-if="displayedWidgets.length" class="row dashboard-main ">
          <draggable class="dashboard-group-left column d-flex flex-col align-items-center justify-content-start flex-wrap bd-highlight mx-2"
                     tag="div"
                     v-bind="dragOptions"
                     @add="saveConfig"
                     @update="saveConfig"
                     handle=".widget-move" :move="onMove" v-for="(column, index) in widgets.columns" :key="index"
                     v-model="widgets.columns[index]">
            <component :is="widget.component" @removeWidget="(name) => removeWidget(name)"
                       v-for="(widget, index) in column" :key="index" class="mb-3"/>
          </draggable>
        </div>
        <h4 class="pt-3" v-else >{{ $t('dashboard.no_widgets') }}</h4>
      </div>
    </b-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import ApiService from '../../apiService'

export default {
  name: 'DashBoard',
  components: {
    draggable
  },
  data () {
    return {
      username: localStorage.getItem('username'),
      editable: true,
      isDragging: false,
      delayedDragging: false,
      widgets: {
        list: [],
        columns: [[], [], []]
      },
      displayedWidgets: []
    }
  },
  mounted () {
    ApiService.getWidgets().then(async resp => {
      let files
      try {
        const widgetsFiles = await require.context('@/components/Widgets', true, /\.vue$/)
        files = widgetsFiles.keys()
      } catch (e) {
        files = ['HelpWidget', 'HolidayWidget', 'InboxWidget', 'RecentWidget', 'SalesWidget', 'SavedWidget', 'TasksWidget']
      }

      for (const file of files) {
        const name = file.replace(/^\.\//, '').replace(/\.\w+$/, '')
        const temp = await import('@/components/Widgets/' + name + '.vue')
        if (name !== 'WidgetTemplate') {
          this.widgets.list.push([name, temp.default.widgetType, temp])
        }
      }

      this.widgets.list.sort((a, b) => {
        if (a[1] > b[1]) {
          return -1
        }
        if (a[1] < b[1]) {
          return 1
        }
        return 0
      })
      if (!resp.data.data.length && (this.hasRole('ROLE_SELLER') || this.hasRole('ROLE_PRE_SELLER'))) {
        const salesWidget = this.widgets.list.findIndex(item => item[0] === 'SalesWidget')
        const inboxWidget = this.widgets.list.findIndex(item => item[0] === 'InboxWidget')
        const tasksWidget = this.widgets.list.findIndex(item => item[0] === 'TasksWidget')

        await this.addWidget(salesWidget, 0, false)
        await this.addWidget(inboxWidget, 1, false)
        await this.addWidget(tasksWidget, 2, false)

        for (let i = 0; i < this.widgets.list.length; i++) {
          await this.addWidget(i, undefined, false)
        }
        this.saveConfig()
      } else if (resp.data.data.length > 0) {
        for (let i = 0; i < resp.data.data.length; i++) {
          const index = this.widgets.list.findIndex(item => item[0] === resp.data.data[i].widget_name)
          if (index > -1) {
            await this.addWidget(index, resp.data.data[i].location, false)
          }
        }
      }
    })
  },
  methods: {
    async addWidget (index, col, save = true) {
      if (!this.displayedWidgets.includes(index)) {
        let addWidgetTo = { key: 0, length: this.widgets.columns[0].length }
        if (col === undefined) {
          for (let i = 0; i < this.widgets.columns.length; i++) {
            if (this.widgets.columns[i].length < addWidgetTo.length && this.widgets.columns[addWidgetTo.key].length !== this.widgets.columns[i].length) {
              addWidgetTo.key = i
            }
          }
        } else {
          addWidgetTo.key = col
        }
        this.widgets.columns[addWidgetTo.key].push({
          value: null, component: (await this.widgets.list[index][2]).default
        })
        this.displayedWidgets.push(index)
        if (save) {
          this.saveConfig()
        }
      }
    },
    removeWidget (name) {
      this.displayedWidgets = this.displayedWidgets.filter(widget => widget !== this.widgets.list.findIndex(widget => widget[0] === name))
      for (let i = 0; i < this.widgets.columns.length; i++) {
        if (this.widgets.columns[i].length) {
          const indexToRemove = this.widgets.columns[i].findIndex(item => item.component.name === name)
          if (indexToRemove > -1) {
            this.widgets.columns[i].splice(indexToRemove, 1)
          }
        }
      }
      ApiService.removeWidget(name)
    },
    saveConfig () {
      const newConfig = [[], [], []]
      for (let i = 0; i < this.widgets.columns.length; i++) {
        for (let x = 0; x < this.widgets.columns[i].length; x++) {
          newConfig[i].push(this.widgets.columns[i][x].component.name)
        }
      }
      ApiService.updateWidgets({ widget_name: newConfig })
    },
    currentDate () {
      const current = new Date()
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
      return current.toLocaleString(undefined, options)
    },
    onMove ({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      )
    }
  },
  computed: {
    ...mapGetters(['getNewMessageCount', 'hasRole']),
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.dashboard-header {
  background-color: $brand-navy;
  background-image: url('../../assets/Dashboard/banner-right.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 150px;

  .dropdown-item {
    min-width: 203px;
  }
}

.background {
  background-image: url('../../assets/Dashboard/banner-left.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  left: 0;
}

.dashboard-header-welcome {
  font-size: 2em;
  line-height: .8em;
  font-weight: bold;
}

.dashboard-cta-1, .dashboard-cta-2, .dashboard-cta-3 {
  font-weight: bold;
  background: $brand-denim;
  transition: 200ms all ease-in-out;
}

.dashboard-cta-1:hover, .dashboard-cta-2:hover, .dashboard-cta-3:hover {
  background: #ffffff;
  color: $brand-denim;
}

.dashboard-cta-header {
  line-height: 32px;
  font-size: 20px;
}

.dashboard-cta-icon {
  font-size: 3em;
}

.ghost {
  opacity: 0.5;
  background: $background-grey;
  margin-bottom: 20px;
}

.dashboard-main {
  display: grid;
  grid-template-columns: 425px 425px 425px;
}

.dropdown-header {
  font-weight: 500;
  color: black;
  padding: 4px 8px !important;
  margin-top: 2px;
}

@media (max-width: 1300px) {
  .dashboard-main {
    grid-template-columns: 425px 425px;
  }

  .dashboard-cta-header {
    font-size: 16px;
  }
  .dashboard-cta-icon {
    font-size: 2.5em;
    line-height: 2em;
  }
}

@media (max-width: 850px) {
  .dashboard-main {
    grid-template-columns: 1fr;
  }
  .column {
    width: 90vw;
    max-width: 406px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-header {
    background-size: contain;
  }
  .dashboard-cta-header {
    line-height: 96px;
  }
  .background {
    height: 378px;
  }
}
</style>
