<template>
  <b-modal id="account-profile-mobile" :title="$t('mobile_number')" modal-class="account-modal" hide-footer>
    <b-alert :show="!!mobileNumberError" variant="danger">
      <i class="fa-solid fa-triangle-exclamation"></i>
      {{ mobileNumberError }}
    </b-alert>
    <b-form @submit="submitForm">
      <b-form-input :placeholder="$t('mobile_number')" class="col-12 col-md-6 offset-md-3" v-model="mobileNumber" type="tel" pattern="^(\+(\d|\s)+)"></b-form-input>
      <hr />
      <p class="text-center">{{ $t('mobile_number_support') }}</p>
      <b-row class="px-3 flex-column-reverse flex-md-row">
        <b-button variant="outline-primary" class="col-12 col-md-3 offset-md-3 mt-3" @click="$bvModal.hide('account-profile-mobile')">{{ $t('cancel') }}</b-button>
        <b-button v-if="!savingForm" variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">{{ $t('save') }}</b-button>
        <b-button v-else disabled variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">
          <b-spinner class="" small label="Large Spinner" />
        </b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'modal-account-mobile',
  props: {
    mobile: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      mobileNumber: this.mobile,
      mobileNumberError: null,
      savingForm: false
    }
  },
  methods: {
    submitForm (e) {
      e.preventDefault()
      this.mobileNumberError = null
      if (this.mobileNumber !== this.mobile) {
        this.savingForm = true
        ApiService.patchUser({ mobile: this.mobileNumber }).then(() => {
          this.$emit('calculateProgress', { mobile: this.mobileNumber })
          this.savingForm = false
          this.$bvModal.hide('account-profile-mobile')
        }).catch((e) => {
          this.mobileNumberError = e.response.data.error_message ? e.response.data.error_message : e.response.data.errors.children.mobile.errors[0]
          this.savingForm = false
        })
      } else {
        this.$bvModal.hide('account-profile-mobile')
      }
    }
  }
}
</script>
