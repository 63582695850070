<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-12 p-0">
            <div class="mr-0 message-list-column h-100">
              <div v-if="!inboxLoaded" class="mx-auto d-flex align-items-center flex-col my-5 d-lg-none d-xl-none">
                <b-spinner class="mb-2" large label="Large Spinner"/>
                <div>{{ $t('loading_inbox') }}</div>
              </div>
              <div v-if="inboxLoaded && inbox.length === 0" class="mx-auto no-messages pt-5 d-lg-none d-xl-none">
                <h3>{{ $t('no_messages') }}</h3>
                <i class="display-4 mb-3 fa fa-comments" aria-hidden="true"/>
              </div>
              <div class="message-list" v-if="inboxLoaded">
                <div id="home" class="message-chat-history-list overflow-hidden">
                  <div class="list-group border-0 " v-for="(message, index) in inbox" :key="index"
                       :id="'message-chat-inbox-' + message.hash"
                       @click="$router.push('inbox/' + message.hash)">
                    <div v-lazy-container="{ selector: 'img' }"
                         class="list-group-item py-3 px-2 pr-3 border-0 message-container d-flex h-100"
                         :id="'messageSeen' + index" :class="{ 'not-seen' : (message.unread > 0) }">
                      <inbox-item :message="message" :current-user="myUsername" :toLastActive="message.to_last_active"
                                  :fromLastActive="message.from_last_active" :ref="'inboxItem' + index"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button class="btn btn-primary my-3 col-11 col-md-5" @click="$router.push('inbox')">
        {{ $t('widgets.InboxWidget.button') }}
      </b-button>
    </template>
  </WidgetTemplate>
</template>

<script>
import ApiService from '../../apiService.js'
import helper from '../../helper'
import { mapActions } from 'vuex'
import InboxItem from '../../components/Message/InboxItem'
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'

export default {
  name: 'InboxWidget',
  widgetType: 'Both',
  component: { WidgetTemplate },
  data () {
    return {
      name: this.$options.name,
      inbox: [],
      inboxLoaded: false,
      fromUsername: '',
      toUsername: '',
      activeLock: '',
      myUsername: localStorage.getItem('username'),
      page: 1,
      perPage: 6
    }
  },
  components: {
    WidgetTemplate,
    InboxItem
  },
  mounted () {
    this.getInbox(this.page, this.perPage)
  },
  methods: {
    ...mapActions([
      'setNewMessageCount'
    ]),
    getInbox (page, perPage) {
      ApiService.getInbox(page, perPage).then(response => {
        this.inbox = response.data.data.results
        this.taskTitle = this.inbox.title
        this.inboxLoaded = true
      })
    },
    formatProfilePhoto (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    unreadDiff (inbox) {
      let oldValue = this.newMessagesHashes
      this.newMessagesHashes = inbox
      return inbox.filter(x => oldValue.indexOf(x) === -1)
    },
    findInboxes (hashes) {
      return this.inbox.filter(function (inbox) {
        return hashes.indexOf(inbox.hash) !== -1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .card-body {
  max-height: 400px;
}

::v-deep ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::v-deep ::-webkit-scrollbar-track {
  border-radius: 10px;
}

::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}

@mixin sm-plus10-down {
  @media (max-width: #{$breakpoint-sm+10}) {
    @content;
  }
}

$grey-border-and-text: #e1e1e1;
$grey-bg: #f5f5f5;
$grey-dark-border: #c4c2c2;
$grey-dark-text: #7c7c7d;

@include sm-plus10-down {
  .message-list .list-group {
    height: auto;
  }
}

::v-deep .new-message-icon {
  background: $brand-sky;
  top: -2px;
  right: -2px;
  padding: 0px 4px;
  border-radius: 20px;
  font-size: 10px;
  color: white;
}

.content-box {
  background: transparent;
  box-shadow: none;
}

.list-group-item {
  border-radius: unset !important;
  border-bottom: 1px solid $grey-border-and-text !important;
  background: transparent;
  cursor: pointer;
}

.message-chat-history .list-group-item:hover {
  box-shadow: none;
}

.message-active,
.message-container:hover {
  background-color: $grey-bg;
}

#message-container {
  border: 1px solid #ddd;
  height: 79vh;
  max-height: 585px;
  background: white;
}

::v-deep .round {
  border-radius: 50% !important;
  max-width: 50px;
}

.message-list-column {
  background: white;
}

::v-deep .message-list {
  font-size: 12px;
  color: $grey-dark-text;
  max-height: 290px;
  overflow-y: scroll;

  .message-list-hash {
    color: $grey-dark-text;
  }

  .message-list-user {
    font-size: 1rem;
    color: $brand-denim;
  }
}

::v-deep .message-list-content {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-messages {
  color: lightslategray;
}

.show {
  display: none;
}

.message-container {
  border-left: 2px solid transparent !important;
}

.message-container.not-seen {
  border-left: 2px solid $brand-tangerine !important;
}

.message-active {
  border-left: 2px solid #44aae1 !important;
}

.message-container ::v-deep .list-box-content .title {
  font-weight: normal !important;
}

.message-box-container {
  height: 58.5vh !important;
}
</style>
