<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <div class="pt-3">
        <b-button class="mb-3 ml-3 mr-3 col-md-5 col-11" target="_blank" variant="outline-primary" href="https://support.anytask.com/hc/en-us/sections/360001495157-Account-Basics">{{ $t('support_account_basics') }}</b-button>
        <b-button class="mb-3 ml-3 mr-3 col-md-5 col-11" target="_blank" variant="outline-primary" href="https://support.anytask.com/hc/en-us/sections/360001495177-Buying-Tasks">{{ $t('support_buying_tasks') }}</b-button>
        <b-button class="mb-3 ml-3 mr-3 col-md-5 col-11" target="_blank" variant="outline-primary" href="https://support.anytask.com/hc/en-us/sections/360001495197-Selling-Tasks">{{ $t('support_selling_tasks') }}</b-button>
        <b-button class="mb-3 ml-3 mr-3 col-md-5 col-11" target="_blank" variant="outline-primary" href="https://support.anytask.com/hc/en-us/sections/360001495217-Disputes">{{ $t('support_disputes') }}</b-button>
      </div>
    </template>
  </WidgetTemplate>
</template>

<script>
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'

export default {
  name: 'HelpWidget',
  widgetType: 'Both',
  components: { WidgetTemplate },
  data () {
    return {
      name: this.$options.name
    }
  }
}
</script>
