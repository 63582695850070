<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <div class="mx-auto my-5 text-center" v-if="!loaded">
        <b-spinner large label="Large Spinner"/>
      </div>
      <div class="flex-row" v-else-if="offers.length > 0">
        <table class="table table-striped add-manage-table border-bottom mb-0">
          <tbody>
          <tr v-for="(offer, index) in offers" :key="index">
            <td>{{ offer.job.title }}</td>
            <td>{{ $t('job.offer.status')[offer.status] }}</td>
            <td>
              <router-link :to="{ name: 'jobs_view', params: { 'hash': offer.job.hash } }" class="btn btn-outline-primary">{{ $t('view') }}</router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="text-center m-3">
        <p>{{ $t('widgets.JobOfferWidget.no_results') }}</p>
      </div>
    </template>
  </WidgetTemplate>
</template>

<script>
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'
import ApiService from '../../apiService'

export default {
  name: 'JobOffersWidget',
  widgetType: 'Buyer',
  components: { WidgetTemplate },
  data () {
    return {
      name: this.$options.name,
      loaded: false,
      offers: []
    }
  },
  mounted () {
    ApiService.getOffers(1).then((resp) => {
      this.offers = resp.data.data.results
      this.loaded = true
    })
  }
}
</script>

<style lang="scss" scoped>
.flex-row {
  max-height: 375px;
  overflow: scroll;
}
</style>
