<template>
  <b-modal id="account-profile-location" :title="$t('location')" modal-class="account-modal" hide-footer>
    <b-alert :show="!!countryError" variant="danger">
      <i class="fa-solid fa-triangle-exclamation"></i>
      {{ countryError }}
    </b-alert>
    <b-form @submit="submitForm">
      <b-form-select
        v-model="country"
        :options="options"
        required
      />
      <hr />
      <b-row class="px-3 flex-column-reverse flex-md-row">
        <b-button variant="outline-primary" class="col-12 col-md-3 offset-md-3 mt-3" @click="$bvModal.hide('account-profile-location')">{{ $t('cancel') }}</b-button>
        <b-button v-if="!savingForm" variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">{{ $t('save') }}</b-button>
        <b-button v-else disabled variant="primary" type="submit" class="col-12 col-md-3 ml-0 ml-md-4 mt-3">
          <b-spinner class="" small label="Large Spinner" />
        </b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'modal-account-location',
  props: {
    countryIso: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      country: this.countryIso,
      countryError: null,
      savingForm: false,
      options: []
    }
  },
  created () {
    this.options = [{ value: '', text: this.$t('choose_location'), disabled: true }]
    for (let key in this.$t('country')) {
      this.options.push({ value: key, text: this.$t('country')[key] })
    }
  },
  methods: {
    submitForm (e) {
      e.preventDefault()
      this.countryError = null
      if (this.countryIso !== this.country) {
        this.savingForm = true
        ApiService.patchUser({ country_iso: this.country }).then(() => {
          this.$emit('calculateProgress', { country: this.country })
          this.savingForm = false
          this.$bvModal.hide('account-profile-location')
        }).catch((e) => {
          this.countryError = e.response.data.errors.children.country_iso.errors[0]
          this.savingForm = false
        })
      } else {
        this.$bvModal.hide('account-profile-location')
      }
    }
  }
}
</script>
