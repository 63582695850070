<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <SalesTable :moreInfo="false" class="w-100"/>
      <b-button class="btn btn-primary mx-auto mb-3 col-11 col-md-4" @click="$router.push('sales')">
        {{ $t('view_sales_button') }}
      </b-button>
    </template>
  </WidgetTemplate>
</template>

<script>
import SalesTable from '../MyAccount/SalesTable'
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'

export default {
  name: 'SalesWidget',
  widgetType: 'Seller',
  components: { SalesTable, WidgetTemplate },
  data () {
    return {
      name: this.$options.name
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table {
  display: block;
  overflow-y: scroll;
  max-height: 300px;
  text-align: center;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}
</style>
