<template>
  <div class="bg-white py-4">
    <b-container>
      <h1-tangerine :title="$t('my_profile')"/>
      <b-row v-if="!showSpinner">
        <b-col class="col-12 col-md-5 col-lg-4 mb-5 mb-md-0">
          <div class="profile-last-login font-italic">
            <p class="mt-2 mb-2">{{ $t('last_logged_in', { date: date(user.last_logged_in) }) }}</p>
          </div>
          <div class="giftcard text-center alert alert-warning d-flex flex-row align-items-center" v-if="getTotalBalance > 0">
            <i class="fa-solid fa-gift" aria-hidden="true"></i>
            <p class="d-flex-inline m-0 flex-1">{{ $t('your_gift_card_balance') }}:<span class="ml-1 font-weight-bold">${{ getTotalBalance }}</span></p>
          </div>
          <div class="p-3 border border-secondary rounded-lg">
            <strong>{{ $t('improve_user_profile') }}</strong>
            <progress id="profile-progress" class="w-100 bg-light radius-lg" max="100" :value="progress"/>
            <p class="small mb-0">{{ $t('improve_user_profile') }}<br/>{{ $t('let_buyers_know_about_you') }}</p>
          </div>
          <div class="profile-action" @click="displayImageModal()">
            <span class="traffic-light" :class="user.photo_path ? 'traffic-light-green' : ''"></span>
            {{ $t('profile_image') }}
            <span class="fa">+</span>
          </div>
          <div class="profile-action" @click="$bvModal.show('account-profile-summary')">
            <span class="traffic-light" :class="user.summary ? 'traffic-light-green' : ''"></span>
            {{ $t('profile_description') }}
            <span class="fa">+</span>
          </div>
          <div class="profile-action" @click="$bvModal.show('account-profile-languages')">
            <span class="traffic-light" :class="user.languages.length > 0 ? 'traffic-light-green' : ''"></span>
            {{ $t('languages') }}
            <span class="fa">+</span>
          </div>
          <div class="profile-action" @click="$bvModal.show('account-profile-location')">
            <span class="traffic-light" :class="user.country_iso ? 'traffic-light-green' : ''"></span>
            {{ $t('location') }}
            <span class="fa">+</span>
          </div>
          <div class="profile-action" @click="$bvModal.show('account-profile-mobile')">
            <span class="traffic-light" :class="user.mobile ? 'traffic-light-green' : ''"></span>
            {{ $t('mobile_number') }}
            <span class="fa">+</span>
          </div>
          <a class="d-block profile-action profile-action-blue" href="https://news.anytask.com/a-best-practice-guide-for-anytask-freelancers" target="_blank">
            {{ $t('seller_guide_tips') }}
            <i class="fa fa-solid fa-circle-question" aria-hidden="true"></i>
          </a>
        </b-col>
        <b-col class="col-12 col-md-7 col-lg-8">
          <div class="bg-light py-4 px-2">
            <b-col class="col-12 col-sm-10 col-lg-8 col-xl-6 offset-sm-1 offset-lg-2 offset-xl-3">
              <UserProfile class="mb-3" :username="user.username" :verify="user.verification_type"
                           :rating="user.review_average" :reviews="user.review_count"
                           :shortDescription="user.summary" :buttons="true" :image_path="userImage"
                           :hash="user.hash" popover_id="profile" :online="user.is_online" :tasks="false"
                           :last-active="user.last_active"
                           :clickable="false"/>
              <InfoBoxSimple class="mb-3 profile-description" type="text" :location="user.country_iso"
                             :join="($t('month.' + (user.joined_date.getMonth() + 1))) + ' ' + user.joined_date.getFullYear()"/>
              <InfoBoxSimple v-if="user.languages.length > 0" class="mb-3 profile-languages" :heading="$t('user_language')" type="list" :content="user.languages"/>
            </b-col>
          </div>
        </b-col>
        <modal-account-image :image="image" v-on:calculateProgress="getUser"/>
        <modal-account-summary :summary="summary" v-on:calculateProgress="calculateProgress"/>
        <modal-account-languages :languages="languages" v-on:calculateProgress="calculateProgress"/>
        <modal-account-location :countryIso="countryIso" v-on:calculateProgress="calculateProgress"/>
        <modal-account-mobile :mobile="mobileNumber" v-on:calculateProgress="calculateProgress"/>
      </b-row>
      <div v-else>
        <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
      </div>
    </b-container>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import H1Tangerine from '../../components/Global/H1Tangerine'
import InfoBoxSimple from '../../components/Task/InfoBoxSimple'
import UserProfile from '../../components/MyAccount/UserProfile.vue'
import helper from '../../helper'
import ModalAccountImage from '../../components/MyAccount/Modal/Image'
import ModalAccountSummary from '../../components/MyAccount/Modal/Summary'
import ModalAccountLanguages from '../../components/MyAccount/Modal/Languages'
import ModalAccountLocation from '../../components/MyAccount/Modal/Location'
import ModalAccountMobile from '../../components/MyAccount/Modal/Mobile'
import { mapActions } from 'vuex'

export default {
  name: 'AccountProfile',
  components: {
    ModalAccountImage,
    ModalAccountSummary,
    ModalAccountLanguages,
    ModalAccountLocation,
    ModalAccountMobile,
    UserProfile,
    H1Tangerine,
    InfoBoxSimple
  },
  data () {
    return {
      showSpinner: true,
      user: null,
      userImage: null,
      progress: 0,
      mobileNumber: '',
      countryIso: '',
      languages: [],
      summary: '',
      image: '',
      hasGiftCard: false
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    ...mapActions([
      'fetchNewAccessToken', 'updateAvatar'
    ]),
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    },
    displayImageModal () {
      this.fetchNewAccessToken().then(() => {
        this.$bvModal.show('account-profile-image')
      })
    },
    getUser () {
      this.showSpinner = true
      ApiService.getUser().then((r) => {
        this.user = r.data.data
        this.user.joined_date = new Date(this.user.member_since)
        this.user.is_online = helper.isUserOnline(this.user.last_active)
        this.mobileNumber = this.user.mobile || ''
        this.countryIso = this.user.country_iso || ''
        this.languages = this.user.languages
        this.summary = this.user.summary || ''
        if (this.user.photo_path && this.$store.state.user.avatar !== this.user.photo_path) {
          setTimeout((vm = this) => {
            vm.updateAvatar(vm.user.photo_path)
            vm.image = vm.user.photo_path || ''
            vm.userImage = vm.user.photo_path || ''
            vm.fetchNewAccessToken()
            this.showSpinner = false
          }, 5000)
        } else {
          this.userImage = this.user.photo_path || ''
          this.image = this.user.photo_path || ''
          this.calculateProgress()
          this.showSpinner = false
        }
      }).catch(() => {
        this.showSpinner = false
      })
    },
    calculateProgress (data = {}) {
      if ('mobile' in data) {
        this.user.mobile = data.mobile
      }

      if ('country' in data) {
        this.user.country_iso = data.country
      }

      if ('languages' in data) {
        this.user.languages = [...data.languages]
      }

      if ('summary' in data) {
        this.user.summary = data.summary
      }

      this.progress = 0
      if (this.user.mobile) {
        this.progress += 20
      }
      if (this.user.country_iso) {
        this.progress += 20
      }
      if (this.user.languages.length > 0) {
        this.progress += 20
      }
      if (this.user.summary) {
        this.progress += 20
      }
      if (this.user.photo_path) {
        this.progress += 20
      }
    }
  },
  computed: {
    getTotalBalance: function () {
      const today = new Date()
      let total = 0
      if (this.user.user_balance.length) {
        this.user.user_balance.forEach(function (item) {
          if (today <= (new Date(item.date_expired))) {
            total += item.amount
          }
        })
        if (total > 0) {
          return total.toFixed(2)
        }
      }

      return 0
    }
  }
}
</script>

<style lang="scss" scoped>

.giftcard {
  .fa-gift {
    font-size: 1.5rem;
  }
  p {
    flex: 1;
  }
}

.profile-last-login {
  text-align: center;
  margin-bottom: 0.8rem;
  border-radius: 0.3rem;
  border: 1px solid #dddddd;
  font-size: .9rem;
}

.profile-action {
  text-align: left;
  cursor: pointer;
  margin-top: 0.8rem;
  padding: .7rem .6rem .6rem .6rem;
  border-radius: .3rem;
  border: 1px solid #dddddd;
  font-size: .9rem;
}

.profile-action:hover {
  background-color: #eeeeee;
}

.profile-action-blue {
  color: $brand-sky;
  border: 1px solid $brand-sky;
}

.profile-action-blue:hover {
  color: #ffffff;
  background-color: $brand-sky;
}

.profile-action .fa {
  font-size: 1.4rem;
  float: right;
  margin-top: -0.1rem;
}

.traffic-light {
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 50%;
  background-color: #dddddd;
  margin-right: 0.4rem;
  display: inline-block;
}

.traffic-light-green {
  background-color: $online-green;
}

progress {
  height: 0.6rem;
}

progress::-moz-progress-bar {
  background: $scarlet-tangerine-gradient;
  border-radius: 0.5rem;
}

progress::-webkit-progress-bar {
  background: #eeeeee;
  border-radius: 0.5rem;
}

progress::-webkit-progress-value {
  background: $scarlet-tangerine-gradient;
  border-radius: 0.5rem;
}
</style>

<style>
.account-modal .modal-dialog {
  max-width: 600px !important;
}

.account-modal .modal-header {
  border: 0;
}

.account-modal .modal-header h5 {
  font-weight: bold;
  font-size: 1.8rem;
  width: 100%;
}

.account-modal .modal-body {
  padding: 1rem !important;
}

.account-modal .modal-body input::placeholder {
  color: #bbb;
}

.account-modal .modal-body hr {
  margin-top: 1.2rem;
  height: 1px;
}
</style>
